import React, { useContext } from 'react';
// import { Sparkles, Infinity, Zap, Crown } from 'lucide-react';
import styles from '../../styles/SubscriptionCTAModal.module.css';
import { GlobalContext } from '../../GlobalContextProvider';

interface SubscriptionCTAModalProps {
    onUpgrade: () => void;
    onClose: () => void;
}

const SubscriptionCTAModal: React.FC<SubscriptionCTAModalProps> = ({
    onUpgrade,
    onClose,
}) => {

    const { setShowRewards } = useContext(GlobalContext)

    const targetDate = new Date('2026-01-13T11:59:59-05:00'); // January 11, 2025 11:59:59 PM EST
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
        const days = 0; //Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        timeLeft = { days, hours, minutes, seconds };
    }

    return (
        <div className={styles["modal-overlay"]} style={{
            fontFamily: "Open Sans"
        }}>
            <div className={styles["cta-modal"]}>
                <div className={styles["modal-header"]}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles["sparkle-icon"]}><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path><path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path></svg>
                    <h2>Unlock Your Full Potential</h2>
                    <p className={styles["subtitle"]}>New Video Special Offer</p>
                    <p className={styles["subtitle2"]}><b>Only {timeLeft.hours + (timeLeft.days * 24)} hours left!</b></p>
                </div>

                <div className={styles["features"]}>
                    <div className={styles["feature"]}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles["lucide lucide-infinity "]}><path d="M12 12c-2-2.67-4-4-6-4a4 4 0 1 0 0 8c2 0 4-1.33 6-4Zm0 0c2 2.67 4 4 6 4a4 4 0 0 0 0-8c-2 0-4 1.33-6 4Z"></path></svg>
                        <span>Unlimited messages</span>
                    </div>
                    <div className={styles["feature"]}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles["lucide lucide-crown "]}><path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14"></path></svg>
                        <span>Priority support</span>
                    </div>
                    <div className={styles["feature"]}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={styles["lucide lucide-zap "]}><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                        <span>Early access to new features</span>
                    </div>
                </div>

                <div className={styles["pricing-highlight"]}>
                    <div className={styles["price"]}>
                        <span className={styles["original"]}>$14.99</span>
                        <span className={styles["current"]}>$7.50</span>
                        <span className={styles["period"]}>/month</span>
                    </div>
                    <span className={styles["save-badge"]}>Save 50%</span>
                </div>

                <div className={styles["modal-actions"]}>
                    <a className={styles["upgrade-button"]} href="/pricing">
                        Upgrade Now
                    </a>
                    <button className={styles["continue-button"]} onClick={() => {
                        onClose()
                        setShowRewards(true)
                    }}>
                        Maybe Later
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionCTAModal;